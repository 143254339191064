/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Stack, Banner, BodyText, Card, Form} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    hr: "hr",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  if (!Form) _missingMdxReference("Form", false);
  if (!Form.Checkbox) _missingMdxReference("Form.Checkbox", true);
  if (!Form.Group) _missingMdxReference("Form.Group", true);
  if (!Stack) _missingMdxReference("Stack", false);
  if (!Stack.Item) _missingMdxReference("Stack.Item", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "A ", React.createElement(_components.strong, null, "Checkbox"), " should be used for On/Off or Yes/No questions. Each checkbox should indicate a separate decision and can be organized into groups of similar options."), "\n", React.createElement(Banner, {
    title: "Do This",
    status: "success"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<label>Attributes</label>\n\t\t<Form.Checkbox label=\"Force User\" />\n\t\t<Form.Checkbox label=\"Pilot\" />\n\t\t<Form.Checkbox label=\"Bounty Hunter\" />\n\t</Form.Group>\n\n\t<Form.Group grouped>\n\t\t<label>Subscribe</label>\n\t\t<Form.Checkbox label=\"Subscribe to updates\" />\n\t</Form.Group>\n\n</Form>\n")), "\n", React.createElement(Banner, {
    title: "Don't do this",
    status: "critical"
  }), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<State initial={0}>\n\t\t{([attributes, setAttributes]) => (\n\t\t\t<Form.Group grouped>\n\t\t\t\t<label>Attributes</label>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel=\"Force User\"\n\t\t\t\t\tchecked={attributes === 1}\n\t\t\t\t\tonChange={() => setAttributes(1)}\n\t\t\t\t/>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel=\"Pilot\"\n\t\t\t\t\tchecked={attributes === 2}\n\t\t\t\t\tonChange={() => setAttributes(2)}\n\t\t\t\t/>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel=\"Bounty Hunter\"\n\t\t\t\t\tchecked={attributes === 3}\n\t\t\t\t\tonChange={() => setAttributes(3)}\n\t\t\t\t/>\n\t\t\t</Form.Group>\n\t\t)}\n\t</State>\n\t<State initial={0}>\n\t\t{([subscribe, setSubscribe]) => (\n\t\t\t<Form.Group grouped>\n\t\t\t\t<label>Subscribe to updates</label>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel=\"No\"\n\t\t\t\t\tchecked={subscribe === 1}\n\t\t\t\t\tonChange={() => setSubscribe(1)}\n\t\t\t\t/>\n\t\t\t\t<Form.Radio\n\t\t\t\t\tlabel=\"Yes\"\n\t\t\t\t\tchecked={subscribe === 2}\n\t\t\t\t\tonChange={() => setSubscribe(2)}\n\t\t\t\t/>\n\t\t\t</Form.Group>\n\t\t)}\n\t</State>\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "sizing",
    style: {
      position: "relative"
    }
  }, "Sizing", React.createElement(_components.a, {
    href: "#sizing",
    "aria-label": "sizing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Checkboxes can be sized to be small, medium, and large. Small checkboxes are useful in small areas, and large checkboxes when a lot of whitespace is present."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form className=\"d-f\">\n\t<Form.Group grouped className=\"flex-grow-1\">\n\t\t<label>Small</label>\n\t\t<Form.Checkbox size=\"small\" label=\"Force User\" />\n\t\t<Form.Checkbox size=\"small\" label=\"Pilot\" />\n\t\t<Form.Checkbox size=\"small\" label=\"Bounty Hunter\" />\n\t</Form.Group>\n\t<Form.Group grouped className=\"flex-grow-1\">\n\t\t<label>Medium (default)</label>\n\t\t<Form.Checkbox label=\"Force User\" />\n\t\t<Form.Checkbox label=\"Pilot\" />\n\t\t<Form.Checkbox label=\"Bounty Hunter\" />\n\t</Form.Group>\n\t<Form.Group grouped className=\"flex-grow-1\">\n\t\t<label>Large</label>\n\t\t<Form.Checkbox size=\"large\" label=\"Force User\" />\n\t\t<Form.Checkbox size=\"large\" label=\"Pilot\" />\n\t\t<Form.Checkbox size=\"large\" label=\"Bounty Hunter\" />\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.h1, {
    id: "states",
    style: {
      position: "relative"
    }
  }, "States", React.createElement(_components.a, {
    href: "#states",
    "aria-label": "states permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Checkboxes can be in an error state and a disabled state."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n  <Form.Group grouped>\n    <label>Default States</label>\n    <Form.Checkbox checked label=\"Force User\" />\n    <Form.Checkbox label=\"Pilot\" />\n  </Form.Group>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n  <Form.Group grouped>\n    <label>Error States</label>\n    <Form.Checkbox error checked label=\"Force User\" />\n    <Form.Checkbox error label=\"Pilot\" />\n  </Form.Group>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Group grouped>\n\t\t<label>Disabled States</label>\n\t\t<Form.Checkbox disabled checked label=\"Force User\" />\n\t\t<Form.Checkbox disabled label=\"Pilot\" />\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "labels-and-helper-text",
    style: {
      position: "relative"
    }
  }, "Labels and Helper Text", React.createElement(_components.a, {
    href: "#labels-and-helper-text",
    "aria-label": "labels and helper text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "no-label-checkboxes",
    style: {
      position: "relative"
    }
  }, "No Label Checkboxes", React.createElement(_components.a, {
    href: "#no-label-checkboxes",
    "aria-label": "no label checkboxes permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A checkbox can be isolated, without margins or label text. This is useful when checkboxes are placed in tables."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Checkbox />\n")), "\n", React.createElement(_components.h2, {
    id: "label-help",
    style: {
      position: "relative"
    }
  }, "Label Help", React.createElement(_components.a, {
    href: "#label-help",
    "aria-label": "label help permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Labels can have a help icon with a ", React.createElement(_components.a, {
    href: "/components/tooltip"
  }, "tooltip"), " to provide additional context to a label."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Checkbox\n\t\tlabel=\"Checkbox Label\"\n\t\tlabelProps={{\n\t\t\thelp: \"This is help text\",\n\t\t\tdirection: 'r'\n\t\t}}\n\t/>\n</Form>\n")), "\n", React.createElement(_components.h2, {
    id: "helper-text",
    style: {
      position: "relative"
    }
  }, "Helper Text", React.createElement(_components.a, {
    href: "#helper-text",
    "aria-label": "helper text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n\t<Form.Checkbox\n\t\tdescription={<span>Our terms of service can be found <Link primary>here</Link></span>}\n\t\tlabel=\"I Agree to Terms of Service\"\n\t/>\n\t<Form.Checkbox\n\t\terror=\"You must agree to create your account.\"\n\t\tlabel=\"I Agree to Terms of Service\"\n\t/>\n\t<Form.Checkbox\n\t\tdescription={<span>Our terms of service can be found <Link primary>here</Link></span>}\n\t\terror=\"You must agree to create your account.\"\n\t\tlabel=\"I Agree to Terms of Service\"\n\t/>\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "indeterminate-checkboxes",
    style: {
      position: "relative"
    }
  }, "Indeterminate Checkboxes", React.createElement(_components.a, {
    href: "#indeterminate-checkboxes",
    "aria-label": "indeterminate checkboxes permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Checkboxes can also be in an ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#Indeterminate_state_checkboxes"
  }, "indeterminate state"), ". This state is commonly used on checkboxes that control groups of items such as the bulk selection checkbox in Gmail."), "\n", React.createElement(_components.p, null, "Clicking a parent checkbox typically either selects or deselects all child checkboxes. When clicking a parent checkbox in an indeterminate state, default to select all."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<State initial={[\n\t{ value: 1, isChecked: false, text: \"Mercury\" },\n\t{ value: 2, isChecked: false, text: \"Venus\" },\n\t{ value: 3, isChecked: true, text: \"Earth\" },\n\t{ value: 4, isChecked: false, text: \"Mars\" }\n]}>\n\t{([state, setState]) => (\n\t\t<Form>\n\t\t\t<Checkbox\n\t\t\t\tchecked={state.every(i => i.isChecked)}\n\t\t\t\tindeterminate={state.some(i => i.isChecked) && !state.every(i => i.isChecked)}\n\t\t\t\tlabel=\"Planets\"\n\t\t\t\tonChange={\n\t\t\t\t\t(value, checked) => setState(prev => prev.map(i => ({...i, isChecked: checked})))\n\t\t\t\t}\n\t\t\t/>\n\t\t\t<Form.Group grouped>\n\t\t\t\t{state.map((item, index) => (\n\t\t\t\t\t<Form.Checkbox\n\t\t\t\t\t\tkey={index}\n\t\t\t\t\t\tchecked={item.isChecked}\n\t\t\t\t\t\tvalue={item.value}\n\t\t\t\t\t\tlabel={item.text}\n\t\t\t\t\t\tonChange={\n\t\t\t\t\t\t\t(value, checked) => setState(prev => prev.map(i => i.value === value ? ({...i, isChecked: checked}) : i))\n\t\t\t\t\t\t}\n\t\t\t\t\t\tclassName=\"m-l-4-i\"\n\t\t\t\t\t/>\n\t\t\t\t))}\n\t\t\t</Form.Group>\n\t\t</Form>\n\t)}\n</State>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "custom-label-position",
    style: {
      position: "relative"
    }
  }, "Custom Label Position", React.createElement(_components.a, {
    href: "#custom-label-position",
    "aria-label": "custom label position permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Checkboxes by default have their label to the left. A custom text label can be applied using the standard ", React.createElement(_components.code, null, "label"), ", ", React.createElement(_components.code, null, "for"), ", and ", React.createElement(_components.code, null, "id"), " combination found with HTML checkboxes."), "\n", React.createElement(_components.h1, {
    id: "examples-of-a-label-on-the-left",
    style: {
      position: "relative"
    }
  }, "Examples of a label on the left", React.createElement(_components.a, {
    href: "#examples-of-a-label-on-the-left",
    "aria-label": "examples of a label on the left permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form style={{width: '155px'}}>\n\t<Form.Group grouped>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"space-between\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"checkbox-id1\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tShort Label\n\t\t\t</BodyText>\n\t\t\t<Form.Checkbox\n\t\t\t\tid=\"checkbox-id1\"\n\t\t\t\tname=\"Checkbox-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"space-between\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"checkbox-id2\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tCustom Label\n\t\t\t</BodyText>\n\t\t\t<Form.Checkbox\n\t\t\t\tid=\"checkbox-id2\"\n\t\t\t\tname=\"Checkbox-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t\t<Stack\n\t\t\talignItems=\"center\"\n\t\t\tjustifyContent=\"space-between\"\n\t\t>\n\t\t\t<BodyText\n\t\t\t\tel=\"label\"\n\t\t\t\thtmlFor=\"checkbox-id3\"\n\t\t\t\tclassName=\"ta-right m-r-1\"\n\t\t\t>\n\t\t\t\tA Longer Label\n\t\t\t</BodyText>\n\t\t\t<Form.Checkbox\n\t\t\t\tid=\"checkbox-id3\"\n\t\t\t\tname=\"Checkbox-sample\"\n\t\t\t/>\n\t\t</Stack>\n\t</Form.Group>\n</Form>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Form>\n    <Form.Group grouped>\n        <Stack\n            alignItems=\"center\"\n            justifyContent=\"flex-end\"\n        >\n            <BodyText\n                el=\"label\"\n                htmlFor=\"checkbox-id4\"\n                className=\"ta-right m-r-1 m-t-1\"\n            >\n                Short Label\n            </BodyText>\n            <Form.Checkbox\n                id=\"checkbox-id4\"\n                name=\"Checkbox-sample\"\n                className=\"m-t-0-i\"\n            />\n        </Stack>\n        <Stack\n            alignItems=\"center\"\n            justifyContent=\"flex-end\"\n        >\n            <BodyText\n                el=\"label\"\n                htmlFor=\"checkbox-id5\"\n                className=\"ta-right m-r-1 m-t-1\"\n            >\n                Custom Label\n            </BodyText>\n            <Form.Checkbox\n                id=\"checkbox-id5\"\n                name=\"Checkbox-sample\"\n                className=\"m-t-0-i\"\n            />\n        </Stack>\n        <Stack\n            alignItems=\"center\"\n            justifyContent=\"flex-end\"\n        >\n            <BodyText\n                el=\"label\"\n                htmlFor=\"checkbox-id6\"\n                className=\"ta-right m-r-1 m-t-1\"\n            >\n                A Longer Label\n            </BodyText>\n            <Form.Checkbox\n                id=\"checkbox-id6\"\n                name=\"Checkbox-sample\"\n                className=\"m-t-0-i\"\n            />\n        </Stack>\n    </Form.Group>\n</Form>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "content-guidelines",
    style: {
      position: "relative"
    }
  }, "Content Guidelines", React.createElement(_components.a, {
    href: "#content-guidelines",
    "aria-label": "content guidelines permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "give-a-descriptive-action-oriented-label",
    style: {
      position: "relative"
    }
  }, "Give a descriptive, action-oriented label", React.createElement(_components.a, {
    href: "#give-a-descriptive-action-oriented-label",
    "aria-label": "give a descriptive action oriented label permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The user relies on the label to provide context and identify what the checkbox controls. Use positive and active wording for checkbox labels. Include a verb to prompt the user to take action."), "\n", React.createElement(_components.p, null, "Use sentence case (capitalize only the first word and proper nouns) so control labels are easy to scan. Do not use periods for short phrases or single sentences."), "\n", React.createElement(_components.p, null, "Checkbox labels should follow the content guidelines for labels."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Checkbox, {
    label: "I agree to the terms of service"
  }), React.createElement(Form.Checkbox, {
    label: "Use the technician’s home business unit by default"
  }), React.createElement(Form.Checkbox, {
    label: "Allow changes"
  })))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Checkbox, {
    label: "I Agree To The Terms of Service."
  }), React.createElement(Form.Checkbox, {
    label: "Use the technician’s home business unit by default."
  }), React.createElement(Form.Checkbox, {
    label: "Disable changes"
  })))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.h2, {
    id: "give-the-user-the-information-they-need-to-make-an-informed-decision",
    style: {
      position: "relative"
    }
  }, "Give the user the information they need to make an informed decision", React.createElement(_components.a, {
    href: "#give-the-user-the-information-they-need-to-make-an-informed-decision",
    "aria-label": "give the user the information they need to make an informed decision permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The checkbox label might not give the required information and context they need to make an informed decision about the option."), "\n", React.createElement(_components.p, null, "Inline help is the best option for this situation because it requires no user interaction and is always visible. Keep inline help to 1-2 sentences."), "\n", React.createElement(_components.p, null, "Use the content guidelines for ", React.createElement(_components.a, {
    href: "/patterns/forms/#inline-help"
  }, "inline help"), "."), "\n", React.createElement(_components.p, null, "Avoid using a Tooltip to communicate this kind of information about an option. It’s an additional step for users to mouse over the Tooltip to reveal its information, and users can’t refer to the Tooltip content and take action at the same time."), "\n", React.createElement(Stack, {
    spacing: 3
  }, React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Form, null, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Checkbox, {
    label: "Enable project labels",
    description: "This allows techs to apply and edit project labels given to job records"
  })))), React.createElement(Banner, {
    status: "success",
    title: "Do"
  })), React.createElement(Stack.Item, {
    grow: 1,
    basis: 0
  }, React.createElement(Card, null, React.createElement(Form, {
    className: "m-b-2"
  }, React.createElement(Form.Group, {
    grouped: true
  }, React.createElement(Form.Checkbox, {
    label: "Enable project labels",
    labelProps: {
      help: "This allows techs to apply and edit project labels given to job records."
    },
    description: "Use the tooltip above"
  })))), React.createElement(Banner, {
    status: "critical",
    title: "Don't"
  }))), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Checkboxes should:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Work independently from each other: selecting one checkbox shouldn’t change the selection status of another checkbox in the list. The exception is when a checkbox is used to make a bulk selection of multiple items."), "\n", React.createElement(_components.li, null, "Be framed positively: for example, Turn on notifications instead of Turn off notifications"), "\n", React.createElement(_components.li, null, "Always have a label when used to toggle a setting on or off"), "\n", React.createElement(_components.li, null, "Be listed according to a logical order, whether it’s alphabetical, numerical, time-based, or some other clear system."), "\n", React.createElement(_components.li, null, "Link to more information or include a subtitle as required to provide more explanation. Don’t rely on tooltips to explain a checkbox."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "related-content",
    style: {
      position: "relative"
    }
  }, "Related Content", React.createElement(_components.a, {
    href: "#related-content",
    "aria-label": "related content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "components",
    style: {
      position: "relative"
    }
  }, "Components", React.createElement(_components.a, {
    href: "#components",
    "aria-label": "components permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "To build a full form, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " component."), "\n", React.createElement(_components.li, null, "For more than 5 options, like choosing tags, a ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/select/"
  }, "Select")), " with multiselect support could be a better option."), "\n", React.createElement(_components.li, null, "To present a list of options where users can only make a single choice, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/radio/"
  }, "Radio")), " component."), "\n", React.createElement(_components.li, null, "To build more prominent checkboxes, or with complex labels, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/togglebox/"
  }, "Togglebox")), " component."), "\n", React.createElement(_components.li, null, "For actions that have immediate results, use the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/toggle-switch"
  }, "Toggle Switch"))), "\n"), "\n", React.createElement(_components.h2, {
    id: "patterns",
    style: {
      position: "relative"
    }
  }, "Patterns", React.createElement(_components.a, {
    href: "#patterns",
    "aria-label": "patterns permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "/patterns/forms/"
  }, "Form"), " design pattern for how related controls are ordered."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We recommend using the ", React.createElement(_components.strong, null, React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), " shorthand component ", React.createElement(_components.code, null, "<Form.Checkbox />"), ". It automatically provide the correct Form grouping structure and spacing. You can import the standalone component for custom Form layouts."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Checkbox } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
